<template>
    <div>
        <FormPagePosition :current-position="3" :back-url="component.fields.formPage2Url" :allow-next="isValid" @next="submitForm"/>
        <div class="page-section bg-white">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h1 class="form-title" v-html="component.fields.consentFormTitle"></h1>
                        <p class="form-subtitle">Authority to collect, hold, use and disclose participant information</p>
                    </div>
                </div>
            </div>
        </div>
        <form method="POST" v-on:submit.prevent="submitForm" :action="component.fields.formPage4Url" class="intake-form">
            <div class="page-section bg-pinkDark">
                <div class="container">
                    <div class="row">
                        <div class="col-12" v-html="component.fields.section1Content">
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-section bg-tanDark">
                <div class="container">
                    <div class="row">
                        <div class="col-12" v-html="component.fields.section2Content">
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-section bg-purpleMain">
                <div class="container">
                    <div class="row intake-form--row">
                        <div class="col-9 intake-form--column">
                            <span class="d-inline-block intake-form--label-large">I give authority for Dawson Plan Managers; to collect, store, use and disclose personal and sensitive information, for the primary purpose of service provision and directly related needs in accordance with the Privacy Amendment (Enhancing Privacy Protection) Act 2012 (Cth) whilst I/we remain a participant of this organisation. If my/our circumstances change I agree to notify Dawson Plan Managers as soon as practicable.</span>
                        </div>
                        <div class="col-3 intake-form--column justify-content-end align-items-end">
                            <FormRadioGroup :initial-value="model.givenAuthority" :title-values="true" :supports-custom-text="false" :on-value-select="updateCheckboxValue" group-name="givenAuthority" :values="['Y', 'N']"></FormRadioGroup>
                        </div>
                    </div>
                    <div class="row intake-form--row">
                        <div class="col-9 intake-form--column">
                            <span class="d-inline-block intake-form--label-large">I will digital sign for these details in the last form</span>
                        </div>
                        <div class="col-3 intake-form--column justify-content-end">
                            <FormRadioGroup :initial-value="model.willDigitallySign" :title-values="true" :supports-custom-text="false" :on-value-select="updateCheckboxValue" group-name="willDigitallySign" :values="['Y', 'N']"></FormRadioGroup>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-12 d-flex align-items-center">
                            <a class="intake-form--between-page" :href="component.fields.formPage2Url">Back</a>
                            <div :class="'intake-form--status'+(!isValid ? ' is-disabled' : '')" v-html="(!isValid ? ' Still some required responses above' : '')"></div>
                            <input type="submit" :disabled="!(isValid && submitting === false)" :class="'intake-form--between-page'+(!isValid || submitting ? ' is-disabled' : '')" value="Next"/>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <FormSaver :current-data="model" current-position="3" :csrf-name="csrfName" :csrf-value="csrfValue" />
    </div>
</template>

<script>
    import FormPagePosition from "./FormPagePosition";
    import FormSaver from "./FormSaver";
    import ky from 'ky';

    export default {
        components: {FormPagePosition, FormSaver},
        computed: {
            submitData() {
                let data = {...this.model};
                data[this.csrfName] = this.csrfValue;
                return data;
            },
            isValid() {
                var isValid = true;

                // dont bother if any child fields are invalid
                if (this.currentlyInvalidFields.length > 0) {
                    isValid = false;
                } else {
                    if (this.model.givenAuthority !== 'Y' || this.model.willDigitallySign !== 'Y') {
                        isValid = false;
                    }
                }

                return isValid;
            }
        },
        data() {
            return {
                submitting: false,
                currentlyInvalidFields: [],
                model: {
                    givenAuthority: null,
                    willDigitallySign: null,
                }
            };
        },
        props: ['component', 'formPage3', 'formPage2', 'formPage1', 'formPage3SubmitUrl', 'csrfName', 'csrfValue'],
        created() {
            if (!this.formPage1 || !this.formPage2) {
                window.location = this.component.fields.formPage2Url;
            }
            if (this.formPage3) {
                this.model = JSON.parse(this.formPage3);
            }
        },
        methods: {
            updateCheckboxValue(groupName, subGroupName, value) {
                if (subGroupName) {
                    this.model[subGroupName][groupName] = value;
                } else {
                    this.model[groupName] = value;
                }
            },
            updateFieldValue(name, value, isValid) {
                this.model[name] = value;
                if (isValid && this.currentlyInvalidFields.includes(name)) {
                    var index = this.currentlyInvalidFields.indexOf(name);
                    if (index > -1) {
                        this.currentlyInvalidFields.splice(index, 1);
                    }
                } else if (!isValid && !this.currentlyInvalidFields.includes(name)) {
                    this.currentlyInvalidFields.push(name);
                }
            },
            afterFormSuccess(data) {
                this.submitting = false;
                window.location = this.component.fields.formPage4Url;
            },
            afterFormError(err) {
                this.submitting = false;
            },
            submitForm(e) {
                if (e) {
                    e.preventDefault();
                }

                this.submitting = true;

                if (!this.isValid) {
                    this.submitting = false;
                    return false;
                } else {
                    ky.post('/formpage3submit', {json: this.submitData}).json().then(this.afterFormSuccess, this.afterFormError);
                }
            }
        }
    }
</script>
