<template>
    <span class="w-100">
        <input :class="'intake-form--input'+(isInvalid ? ' is-invalid' : '')" type="text" :id="name" :name="name" v-model="inputValue" :placeholder="label"/>
        <span v-if="required && isInvalid" class="text-danger">*Required</span>
    </span>
</template>

<script>
    export default {
        data() {
            return {
                inputValue: this.value
            }
        },
        computed: {
            isInvalid() {
                if (this.required && (this.inputValue === null || this.inputValue === '')) {
                    return true;
                }

                if (this.inputValue.length > 0) {
                    return !(new RegExp('^(?:\\+?61|0)[2-478](?:[ -]?[0-9]){8}$').test(this.inputValue));
                } else {
                    return false;
                }
            }
        },
        props: ['type', 'value', 'label', 'name', 'required'],
        watch: {
            inputValue(val) {
                this.$emit('field-changed', this.name, val, !(this.isInvalid));
            }
        }
    }
</script>