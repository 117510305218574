import Vue from 'vue'

// Automatically register all Vue components located within the /src/components folder.

const requireComponent = require.context(
    // The relative path of the components folder
    './components',
    // Whether or not to look in subfolders
    true,
    // The regular expression used to match base component filenames
    /[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
    // Get component config
    const componentConfig = requireComponent(fileName)

    let cleanFileName = fileName.replace(/^\.\/(.*)\.\w+$/, '$1');
    if (cleanFileName.includes('/')) {
        cleanFileName = cleanFileName.replace('/', '');
    }

    // Register component globally
    Vue.component(
        cleanFileName,
        // Look for the component options on `.default`, which will
        // exist if the component was exported with `export default`,
        // otherwise fall back to module's root.
        componentConfig.default || componentConfig
    )
})