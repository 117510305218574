<template>
    <div class="contact-form row">
        <div class="col-12">
            <div class="row">
                <div class="col-md-6">
                    <label for="yourName">Name</label>
                    <input type="text" id="yourName" name="yourName" class="form-control" :class="classForField('name')" placeholder="John Smith" v-model="submission.name" :disabled="submitting" />
                    <field-errors :errors="errorsForField('name')" />
                </div>
                <div class="col-md-6">
                    <label for="email">Email</label>
                    <input type="email" id="email" name="email" class="form-control" :class="classForField('email')" placeholder="john@example.com" v-model="submission.email" :disabled="submitting" />
                    <field-errors :errors="errorsForField('email')" />
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label for="phone">Contact Number</label>
                    <input type="tel" id="phone" name="phone" class="form-control" :class="classForField('phone')" placeholder="0412 345 678" v-model="submission.phone" :disabled="submitting" />
                    <field-errors :errors="errorsForField('phone')" />
                </div>
                <div class="col-md-6">
                    <label for="category">I am a...</label>
                    <div class="select-style form-control" :class="classForField('category')" >
                        <select v-model="submission.category" name="category" :disabled="submitting">
                            <option v-for="option in categoryOptions" :value="option" v-html="option"></option>
                        </select>
                    </div>
                    <field-errors :errors="errorsForField('category')" />
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <label for="type">Type of Enquiry</label>
                    <div class="select-style form-control" :class="classForField('type')">
                        <select v-model="submission.type" name="type" :disabled="submitting">
                            <option v-for="option in typeOptions" :value="option" v-html="option"></option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <label for="message">Add a message (optional)</label>
                    <textarea id="message" name="message" rows="6" class="form-control" :class="classForField('message')" placeholder="Type your message..." v-model="submission.message" :disabled="submitting"></textarea>
                    <field-errors :errors="errorsForField('message')" />
                </div>
            </div>
            <div class="row">
                <div class="col-12 mt-3">
                    <a href="#" @click.prevent="submitForm" class="btn btn-main" :class="submitting && 'disabled'">Send Message</a>
                    <i class="fa fa-spinner fa-spin submit-indicator ml-3" v-if="submitting"></i>
                </div>
                <div class="col-12 mt-3" v-if="submitMessage">
                    <div class="submit-message" :class="submitError && 'submit-errors'">
                        <span v-html="submitMessage"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ky from 'ky';

    export default {
        data() {
            return {
                submission: {
                    name: null,
                    email: null,
                    phone: null,
                    category: 'NDIS Recipient',
                    type: 'General',
                    message: null
                },
                categoryOptions: [
                    'NDIS Recipient',
                    'Supplier for the NDIS',
                    'Other'
                ],
                typeOptions: [
                    'General',
                    'Complaint',
                    'Feedback'
                ],
                submitting: false,
                submitMessage: '',
                submitError: false,
                formErrors: {}
            };
        },
        props: ['csrfName', 'csrfValue'],
        computed: {
            submitData() {
                let data = {...this.submission};
                data[this.csrfName] = this.csrfValue;
                return data;
            }
        },
        methods: {
            submitForm() {
                this.submitting = true;
                this.submitMessage = null;
                this.submitError = false;
                this.formErrors = {};
                ky.post('/contact', {json: this.submitData}).json().then(this.afterFormSuccess, this.afterFormError);
            },
            afterFormSuccess(data) {
                this.submitting = false;
                this.submitMessage = data.message || 'An unknown error occurred, please try again.';
                this.submitError = !(data.success);
                if (!data.success) {
                    this.formErrors = data.formErrors;
                } else {
                    this.resetForm();
                    this.formErrors = {};
                }
            },
            afterFormError(err) {
                this.submitting = false;
                this.submitError = true;
                this.submitMessage = err.message || 'An unknown error occurred, please try again.';
            },
            classForField(fieldName) {
                let classes = [];

                if (this.submitting) {
                    classes.push('disabled');
                }

                if (this.formErrors[fieldName] !== undefined) {
                    classes.push('has-error');
                }

                return classes;
            },
            errorsForField(fieldName) {
                return (this.formErrors[fieldName] !== undefined) ? this.formErrors[fieldName] : [];
            },
            resetForm() {
                this.submission = {
                    name: null,
                    email: null,
                    phone: null,
                    category: 'NDIS Recipient',
                    type: 'General',
                    message: null
                }
            }
        }
    }
</script>
