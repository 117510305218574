<template>
    <div class="footer">
        <div class="container">
            <div class="row">
                <div class="col-md-2 footer-logo">
                    <a href="/">
                        <img :src="footer.logo[0]" alt="footer logo" />
                    </a>
                </div>
                <div class="col-md-6 offset-md-4 footer-links">
                    <a v-for="link in footer.menuLinks" :href="link.fields.linkUrl" :target="link.fields.openInNewTab && '_blank'" v-html="link.fields.linkText"></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['entry', 'footer']
    }
</script>
