<template>
    <div :class="'page-hero'+(component.fields.heroSubsectionText ? ' has-subsection' : '')" :id="component.fields.sectionId">
        <div :class="'hero-content half-container half-container-left'+(component.fields.heroSubsectionText ? ' has-subsection' : '')">
            <h2 v-html="component.fields.heroText"></h2>
            <a class="btn btn-main" :href="component.fields.heroButtonUrl" v-if="component.fields.heroButtonUrl" v-html="component.fields.heroButtonText"></a>
            <div v-if="component.fields.heroSubsectionText" class="hero-subsection">
                <strong class="text-bold">{{component.fields.heroSubsectionText}}</strong>
            </div>
        </div>
        <div :class="'hero-image'+(component.fields.heroSubsectionText ? ' half-container half-container-right' : '')">
            <img :src="component.fields.heroImage" />
        </div>
    </div>
</template>

<script>
    export default {
        props: ['entry', 'component', 'footer', 'header', 'currentslug', 'csrfName', 'csrfValue', 'componentKey']
    }
</script>
