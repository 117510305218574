<template>
    <div class="page-section people" :id="component.fields.sectionId">
        <div class="container">
            <div class="row">
                <div class="offset-md-1 col-md-3">
                    <h4 v-html="f.sectionTitle" :style="`color:${component.fields.sectionTitleColour};`"></h4>
                </div>
                <div class="col-md-8 people-rows">
                    <div v-for="row in batch(f.people, 2)" class="row">
                        <div v-for="(column, index) in row" :class="'col-md-'+(index === 0 ? '5' : '5 offset-md-2')+' people-content-item'">
                            <img :src="column.fields.personImage[0]" :alt="column.fields.personName" class="img-fluid w-100 h-auto"/>
                            <h5 class="mt-3" v-html="column.fields.personName" :style="`color:${column.fields.personNameColour};`"></h5>
                            <p v-html="column.fields.personDescription" v-if="column.fields.personDescription" :style="`color:${column.fields.personDescriptionColour};`"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'People',
        props: ['entry', 'component', 'footer', 'header', 'currentslug', 'csrfName', 'csrfValue', 'componentKey'],
        computed: {
            f() {
                return this.component.fields;
            }
        }
    }
</script>