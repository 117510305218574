<template>
    <div sticky-container>
        <div class="header-container">
            <Header :header="header" :site-meta="siteMeta"></Header>
            <template v-if="entry.typeId !== '3'">
            <component v-if="topComponent"
                       :entry="entry"
                       :footer="footer"
                       :header="header"
                       :site-meta="siteMeta"
                       :site-settings="siteSettings"
                       :currentslug="slug"
                       :csrf-name="csrfName"
                       :form-page1="formPage1"
                       :form-page2="formPage2"
                       :form-page3="formPage3"
                       :form-page4="formPage4"
                       :csrf-value="csrfValue"
                       :component="topComponent"
                       :is="topComponent.type|camelToTitle"/>
            </template>
            <template v-if="entry.typeId === '3'">
            <Hero :component="getHeroForNews" :entry="entry" :footer="footer" :site-settings="siteSettings" :currentslug="slug" :header="header"></Hero>
            </template>
        </div>

        <template v-if="entry.typeId !== '3'">
        <component v-for="(component,index) in otherComponents"
                   :entry="entry"
                   :footer="footer"
                   :header="header"
                   :site-meta="siteMeta"
                   :site-settings="siteSettings"
                   :currentslug="slug"
                   :csrf-name="csrfName"
                   :csrf-value="csrfValue"
                   :component="component"
                   :form-page1="formPage1"
                   :form-page2="formPage2"
                   :form-page3="formPage3"
                   :form-page4="formPage4"
                   :is="component.type|camelToTitle"
                   :componentKey="index"
                   :key="index"></component>
        </template>
        <template v-if="entry.typeId === '3'">
            <Columns :component="getColumnForNews" :entry="entry" :footer="footer" :site-settings="siteSettings" :currentslug="slug" :header="header"></Columns>
            <component v-for="(component,index) in components"
                       :entry="entry"
                       :footer="footer"
                       :header="header"
                       :site-meta="siteMeta"
                       :site-settings="siteSettings"
                       :currentslug="slug"
                       :csrf-name="csrfName"
                       :csrf-value="csrfValue"
                       :component="component"
                       :form-page1="formPage1"
                       :form-page2="formPage2"
                       :form-page3="formPage3"
                       :form-page4="formPage4"
                       :is="component.type|camelToTitle"
                       :componentKey="index"
                       :key="index"></component>
        </template>

        <div class="footer-container">
            <component v-if="bottomComponent"
                       :no-background="true"
                       :entry="entry"
                       :footer="footer"
                       :header="header"
                       :site-meta="siteMeta"
                       :site-settings="siteSettings"
                       :currentslug="slug"
                       :csrf-name="csrfName"
                       :form-page1="formPage1"
                       :form-page2="formPage2"
                       :form-page3="formPage3"
                       :form-page4="formPage4"
                       :csrf-value="csrfValue"
                       :component="bottomComponent"
                       :is="bottomComponent.type|camelToTitle"/>
            <Footer :footer="footer"></Footer>
        </div>

    </div>
</template>

<script>
    import $ from 'jquery'
    import Hero from './Hero.vue';
    import Footer from './Footer.vue';
    import Header from "./Header";
    import Columns from "./Columns";

    export default {
        name: 'MainLayout',
        props: ['entry', 'entryFields', 'components', 'header', 'footer', 'siteSettings', 'siteMeta', 'csrfName', 'csrfValue', 'error', 'formPage1', 'formPage2', 'formPage3', 'formPage4'],
        components: {
            Columns,
            Header,
            Footer,
            Hero
        },
        computed: {
            topComponent() {
                return this.components.length ? this.components[0] : null;

            },
            bottomComponent() {
                if (this.entry.slug !== 'home') {
                    return null;
                }

                let len = this.components.length;
                return len > 1 ? this.components[len - 1] : null;
            },
            otherComponents() {
                if (this.entry.slug !== 'home') {
                    return this.components.slice(1);
                }

                return this.components.length > 2 ? this.components.slice(1, -1) : [];
            },
            getHeroForNews() {
                return {
                    fields: {
                        heroText: this.entry.title,
                        heroSubsectionText: this.entryFields.author + ' - ' + this.$root.formatTimestamp(this.entry.postDate.date),
                        heroImage: this.entryFields.featuredImage
                    }
                };
            },
            getColumnForNews() {
                return {
                    fields: {
                        leftColumnWidth: 'col-12',
                        leftColumnContent: this.entryFields.postContent,
                        leftColumnImage: false,
                        noBackground: true,
                        widthConstrained: true,
                        backgroundImage: false,
                        rightColumnWidth: 0,
                        rightColumnContent: false,
                        rightColumnImage: false,
                    }
                };
            }
        },
        data() {
            return {
                slug: this.entry.slug
            }
        },
    }
</script>
