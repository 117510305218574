<template>
    <div class="field-errors" v-if="errors.length">
        <span v-if="errors.length === 1" v-html="errors[0]"></span>
        <ul v-else>
            <li v-for="error in errors" v-html="error"></li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'field-errors',
        props: ['errors']
    }
</script>
