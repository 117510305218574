<template>
    <div class="form-saver" :class="hidden && 'hidden'">
        <a href="#" @click.prevent="hidden = !hidden" class="show-hide-form-saver">
            <i v-if="hidden" class="fa fa-chevron-up"></i>
            <i v-else class="fa fa-chevron-down"></i>
        </a>
        <a href="#" @click.prevent="doFormSave" class="btn btn-primary" v-if="!submitting">Save Progress and Share</a>
        <template v-if="saveUrl && !submitting">
            <div>
                <i class="fa fa-check" />&nbsp;Progress saved and link copied<br/>
                <input type="text" v-model="saveUrl" id="save-url" />
            </div>
            <div>
                Share your submission with this URL<br/>Please note the link expires in 7 days
            </div>
        </template>
        <template v-if="submitting">
            <i class="fa fa-2x fa-spinner fa-spin" />
        </template>
    </div>
</template>

<script>
    import ky from 'ky';

    export default {
        data() {
            return {
                hidden: false,
                submitting: false,
                saveUrl: null
            }
        },
        props: ['currentPosition', 'currentData', 'csrfName', 'csrfValue'],
        computed: {
            submitData() {
                let data = {
                    currentStep: this.currentPosition,
                    currentData: this.currentData,
                    currentUrl: window.location.href,
                };
                data[this.csrfName] = this.csrfValue;
                return data;
            }
        },
        methods: {
            afterFormSuccess(data) {
                this.submitting = false;
                this.saveUrl = data.retrieveUrl;

                this.$nextTick(function() {
                    let copyText = document.getElementById("save-url");
                    copyText.select();
                    copyText.setSelectionRange(0, 99999);
                    document.execCommand("copy");
                });

            },
            afterFormError(err) {
                this.submitting = false;
            },
            doFormSave() {
                this.saveUrl = null;
                this.submitting = true;
                ky.post('/formsaveprogress', {json: this.submitData}).json().then(this.afterFormSuccess, this.afterFormError);
            },
            copyToClipboard() {

            }
        }
    }
</script>
