<template>
    <div class="header">
        <div v-sticky>
            <div class="container d-lg-block d-none">
                <div class="row">
                    <div class="col-lg-2 col-4 header-logo">
                        <a href="/"><img :src="header.logo[0]" alt="logo" /></a>
                    </div>
                    <div class="col-lg-7 col-sm-5 header-nav">
                        <a v-for="link in header.menuLinks" :href="getHrefForLink(link)" @click.prevent="maybeScroll(link)" v-html="link.fields.linkText" :target="link.fields.openInNewTab && '_blank'"></a>
                    </div>
                    <div class="col-lg-3 col-8 header-buttons">
                        <div class="d-lg-flex d-none" v-if="siteMeta.socialMediaLinks.length">
                            <a class="social-link" :href="siteMetaItem.fields.socialMediaLink" :target="siteMetaItem.fields.openInNewTab && '_blank'" v-for="siteMetaItem in siteMeta.socialMediaLinks">
                                <i :class="'text-white fa fa-lg fa-'+(siteMetaItem.fields.socialMediaType)"></i>
                            </a>
                        </div>
                        <a v-for="button in header.menuButtons" class="btn btn-primary bg-purpleDark" :href="getHrefForLink(button, 'buttonUrl')" @click.prevent="maybeScroll(button, 'buttonUrl')" :target="button.fields.openInNewTab && '_blank'" v-html="button.fields.buttonText"></a>
                    </div>
                </div>
            </div>
        </div>
        <nav class="navbar d-flex d-lg-none navbar-dark bg-primary position-fixed w-100 top-0 left-0">
            <a href="/" class="navbar-brand"><img :src="header.logo[0]" class="h-auto" alt="logo" /></a>
            <button class="navbar-toggler text-white" type="button" data-toggle="collapse" data-target="#mobile-menu" aria-controls="mobile-menu" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon text-white"></span>
            </button>

            <div class="collapse navbar-collapse" id="mobile-menu">
                <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
                    <li class="nav-item" v-for="link in header.menuLinks">
                        <a class="nav-link text-white" :href="getHrefForLink(link)" @click.prevent="maybeScroll(link)" v-html="link.fields.linkText" :target="link.fields.openInNewTab && '_blank'"></a>
                    </li>
                    <li class="nav-item" v-for="button in header.menuButtons">
                        <a class="nav-link text-white" :href="getHrefForLink(button, 'buttonUrl')" @click.prevent="maybeScroll(button, 'buttonUrl')" :target="button.fields.openInNewTab && '_blank'" v-html="button.fields.buttonText"></a>
                    </li>
                    <li class="nav-item w-100" v-if="siteMeta.socialMediaLinks.length">
                        <a class="mr-3" :href="siteMetaItem.fields.socialMediaLink" :target="siteMetaItem.fields.openInNewTab && '_blank'" v-for="siteMetaItem in siteMeta.socialMediaLinks">
                            <i :class="'text-white fa fa-lg fa-'+(siteMetaItem.fields.socialMediaType)"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</template>

<script>
    export default {
        props: ['entry', 'header', 'siteMeta'],
        methods: {
            getHrefForLink(link, fieldName = 'linkUrl') {
                return this.isScrollLink(link.fields[fieldName]) ? ('#' + link.fields[fieldName]) : link.fields[fieldName];
            },
            getScrollToForLink(link, fieldName = 'linkUrl') {
                return this.isScrollLink(link.fields[fieldName]) ? ('#' + link.fields[fieldName]) : null;
            },
            isScrollLink(link) {
                return !(link.charAt(0) === "/" || link.includes('http') || link.includes('www'));
            },
            maybeScroll(link, fieldName = 'linkUrl') {
                const scrollTo = this.getScrollToForLink(link, fieldName);
                if (scrollTo) {
                    this.$scrollTo(scrollTo)
                } else {
                    window.location.href = this.getHrefForLink(link, fieldName);
                }
            }
        }
    }
</script>
