import 'url-search-params-polyfill'
import 'isomorphic-fetch'
import 'bootstrap'

import Vue from 'vue'
import Multiselect from 'vue-multiselect'
import VueSignaturePad from 'vue-signature-pad'
import VueAnime from 'vue-animejs';
import Inview from 'vueinview'
import Sticky from 'vue-sticky-directive'
import Components from './components'
import './assets/styles/front/app.scss'
let moment = require('moment');

Vue.use(Inview);
Vue.use(VueAnime);
Vue.use(Sticky)
Vue.use(VueSignaturePad);
Vue.component('multiselect', Multiselect);
Vue.config.productionTip = false;
Vue.filter('camelToTitle', function(value) {
    if (!value) return '';

    let firstLetter = value.substring(0, 1);
    let restOf = value.substring(1);
    return firstLetter.toUpperCase() + restOf;
});

var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo);

Vue.mixin({
    methods: {
        batch(items, batchSize) {
            let result = [];
            let data = items.slice(0);
            while (data[0]) {
                result.push(data.splice(0, batchSize));
            }
            return result;
        },
        nl2br(value) {
            if (!value) return '';
            value = value.toString();
            return value.replace(/(?:\r\n|\r|\n)/g, '<br />');
        }
    }
});

new Vue({
    el: '#app',
    delimiters: ['${', '}'],
    methods: {
        formatTimestamp(value) {
            if (!value) return '';

            return moment(value).format('D/MM/YYYY');
        },
        vh(v) {
            var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
            return (v * h) / 100;
        },
        vw(v) {
            var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
            return (v * w) / 100;
        },
        getLineLength(line) {
            let x1 = line.x1.baseVal.value;
            let x2 = line.x2.baseVal.value;
            let y1 = line.y1.baseVal.value;
            let y2 = line.y2.baseVal.value;

            return Math.sqrt( (x2-=x1)*x2 + (y2-=y1)*y2 );
        },
        isWindows() {
            return navigator.platform.indexOf('Win') > -1
        },
        isNotDesktop() {
            return navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i);
        },
        isLandscape() {
            return window.innerWidth > window.innerHeight;
        },
        slugifyNumberOfWords(text, number) {
            text = text.replace(/[.,"#&'!]/g, '');
            let words = text.split(" ");
            let slug = [];
            for (let x = 0; x < words.length && x < numbeFr; x++) {
                slug.push(words[x]);
            }
            return slug.join('-').toLowerCase();
        },
        strip(html) {
            let doc = new DOMParser().parseFromString(html, 'text/html');
            return doc.body.textContent || "";
        }
    }
})
