<template>
    <div>
        <FormPagePosition :current-position="1" :back-url="null" :allow-next="isValid" @next="submitForm" />
        <div class="page-section bg-white">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h1 class="form-title">Participant Intake Form</h1>
                        <p class="form-subtitle">Funding and NDIS information, Participant details, and preferences</p>
                    </div>
                </div>
            </div>
        </div>
        <form method="POST" v-on:submit.prevent="submitForm" :action="component.fields.formPage2Url" class="intake-form" enctype="multipart/form-data">
            <div class="page-section bg-pinkDark">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h4 class="intake-form--title">1. Funding</h4>
                        </div>
                    </div>
                    <div class="row intake-form--row">
                        <div class="col-12 intake-form--column">
                            <span class="mr-4">Plan Managed (Improved life choices budget item)</span>
                            <FormRadioGroup sub-group-name="radios" :initial-value="model.radios.planManaged" :supports-custom-text="false" :on-value-select="updateCheckboxValue" group-name="planManaged" :values="['Y', 'N']"></FormRadioGroup>
                        </div>
                    </div>
                    <div class="row intake-form--row">
                        <div class="col-2 intake-form--column">
                            <label for="ndisNumber" class="mr-1">NDIS Number</label>
                        </div>
                        <div class="col-lg-6 col-10 intake-form--column">
                            <FormTextField name="ndisNumber" label="NDIS Number" :value="model.ndisNumber" @field-changed="updateFieldValue" :required="true"></FormTextField>
                        </div>
                    </div>
                    <div class="row intake-form--row">
                        <div class="col-2 intake-form--column">
                            <label for="ndisDateDay" class="mr-1">NDIS Date</label>
                        </div>
                        <div class="col-3 col-lg-2 intake-form--column">
                            <FormDateField type="day" name="ndisDateDay" :required="true" :value="model.ndisDateDay" label="Day" @field-changed="updateFieldValue"></FormDateField>
                        </div>
                        <div class="col-3 col-lg-2 intake-form--column">
                            <FormDateField type="month" name="ndisDateMonth" :required="true" :value="model.ndisDateMonth" label="Month" @field-changed="updateFieldValue"></FormDateField>
                        </div>
                        <div class="col-3 col-lg-2 intake-form--column">
                            <FormDateField type="year" name="ndisDateYear" :required="true" :value="model.ndisDateYear" label="Year" @field-changed="updateFieldValue"></FormDateField>
                        </div>
                    </div>
                    <div class="row intake-form--row">
                        <div class="col-2 intake-form--column">
                            <label for="ndisContact" class="mr-1">Dawson Plan Managers Contact</label>
                        </div>
                        <div class="col-lg-6 col-10 intake-form--column">
                            <FormTextField name="ndisContact" label="Dawson Plan Managers Contact" :value="model.ndisContact" @field-changed="updateFieldValue" :required="false"></FormTextField>
                        </div>
                    </div>
                    <div class="row intake-form--row">
                        <div class="col-2 intake-form--column">
                            <label for="ndisPlan" class="mr-1">NDIS Plan</label>
                        </div>
                        <div class="col-lg-6 col-10 intake-form--column">
                            <FormFileField name="ndisPlan" label="NDIS Plan" :value="model.ndisPlan" @field-changed="updateFieldValue" :required="false"></FormFileField>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-section bg-tanDark">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h4 class="intake-form--title">2. Participant Details</h4>
                        </div>
                    </div>
                    <div class="row intake-form--row">
                        <div class="col-2 intake-form--column">
                            <label for="firstName" class="mr-1">Participant Name</label>
                        </div>
                        <div class="col-3 col-lg-2 intake-form--column">
                            <FormTextField name="firstName" label="First Name" :value="model.firstName" @field-changed="updateFieldValue" :required="true"></FormTextField>
                        </div>
                        <div class="col-3 col-lg-2 intake-form--column">
                            <FormTextField name="otherNames" label="Other Names" :value="model.otherNames" @field-changed="updateFieldValue" :required="false"></FormTextField>
                        </div>
                        <div class="col-3 col-lg-2 intake-form--column">
                            <FormTextField name="lastName" label="Last Name" :value="model.lastName" @field-changed="updateFieldValue" :required="true"></FormTextField>
                        </div>
                    </div>
                    <div class="row intake-form--row">
                        <div class="col-2 intake-form--column">
                            <label for="dobYear" class="mr-1">Date of Birth</label>
                        </div>
                        <div class="col-3 col-lg-2 intake-form--column">
                            <FormDateField type="day" name="dobDay" :required="true" :value="model.dobDay" label="Day" @field-changed="updateFieldValue"></FormDateField>
                        </div>
                        <div class="col-3 col-lg-2 intake-form--column">
                            <FormDateField type="month" name="dobMonth" :required="true" :value="model.dobMonth" label="Month" @field-changed="updateFieldValue"></FormDateField>
                        </div>
                        <div class="col-3 col-lg-2 intake-form--column">
                            <FormDateField type="year" name="dobYear" :required="true" :value="model.dobYear" label="Year" @field-changed="updateFieldValue"></FormDateField>
                        </div>
                    </div>
                    <div class="row intake-form--row">
                        <div class="col-12 intake-form--column">
                            <span class="mr-4">Gender</span>
                            <FormRadioGroup :initial-value="model.gender" :supports-custom-text="true" :custom-text-value="'Other'" :on-value-select="updateCheckboxValue" :large-values="true" group-name="gender" :values="['M', 'F', 'Other']"></FormRadioGroup>
                        </div>
                    </div>
                    <div class="row intake-form--row mt-5">
                        <div class="col-3 intake-form--column">
                            <label for="language" class="mr-1">Language spoken at home</label>
                        </div>
                        <div class="col-3 col-lg-3 intake-form--column">
                            <FormTextField name="language" label="Language" :value="model.language" @field-changed="updateFieldValue" :required="true"></FormTextField>
                        </div>
                    </div>
                    <div class="row intake-form--row">
                        <div class="col-12 intake-form--column">
                            <span class="mr-4">Interpreter Required?</span>
                            <FormRadioGroup sub-group-name="radios" :initial-value="model.radios.interpreter" :supports-custom-text="false" :on-value-select="updateCheckboxValue" group-name="interpreter" :values="['Y', 'N']"></FormRadioGroup>
                        </div>
                    </div>
                    <div class="row intake-form--row">
                        <div class="col-12 intake-form--column">
                            <span class="mr-4">Do you identify a Aboriginal and/or Torres Strait Islander?</span>
                            <FormRadioGroup sub-group-name="radios" :initial-value="model.radios.atsi" :supports-custom-text="false" :on-value-select="updateCheckboxValue" group-name="atsi" :values="['Y', 'N']"></FormRadioGroup>
                        </div>
                    </div>
                    <span class="mt-5 d-inline-block">Contact Details</span>
                    <div class="row intake-form--row mt-3">
                        <div class="col-2 intake-form--column">
                            <label for="phone" class="mr-1">Phone</label>
                        </div>
                        <div class="col-3 col-lg-2 intake-form--column">
                            <FormPhoneField name="phone" label="Phone" :value="model.phone" @field-changed="updateFieldValue" :required="true"></FormPhoneField>
                        </div>
                    </div>
                    <div class="row intake-form--row">
                        <div class="col-2 intake-form--column">
                            <label for="email" class="mr-1">Email</label>
                        </div>
                        <div class="col-3 col-lg-2 intake-form--column">
                            <FormEmailField name="email" label="Email" :value="model.email" @field-changed="updateFieldValue" :required="true"></FormEmailField>
                        </div>
                    </div>
                    <div class="row intake-form--row">
                        <div class="col-12 intake-form--column">
                            <span class="mr-4">Preferred Option for Communication</span>
                            <FormRadioGroup :initial-value="model.communication" :supports-custom-text="false" :on-value-select="updateCheckboxValue" :large-values="true" group-name="communication" :values="['Email', 'Post', 'Phone', 'SMS']"></FormRadioGroup>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-lg-5">
                            <span class="d-inline-block mb-4">Residential Address</span>
                            <div class="row intake-form--row">
                                <div class="col-6 intake-form--column">
                                    <label for="residentialUnitStreetNumber" class="mr-1">Unit/Street Number</label>
                                </div>
                                <div class="col-6 intake-form--column">
                                    <FormTextField name="residentialUnitStreetNumber" label="" :value="model.residentialUnitStreetNumber" @field-changed="updateFieldValue" :required="true"></FormTextField>
                                </div>
                            </div>
                            <div class="row intake-form--row">
                                <div class="col-6 intake-form--column">
                                    <label for="residentialStreet" class="mr-1">Street</label>
                                </div>
                                <div class="col-6 intake-form--column">
                                    <FormTextField name="residentialStreet" label="" :value="model.residentialStreet" @field-changed="updateFieldValue" :required="true"></FormTextField>
                                </div>
                            </div>
                            <div class="row intake-form--row">
                                <div class="col-6 intake-form--column">
                                    <label for="residentialCity" class="mr-1">City</label>
                                </div>
                                <div class="col-6 intake-form--column">
                                    <FormTextField name="residentialCity" label="" :value="model.residentialCity" @field-changed="updateFieldValue" :required="true"></FormTextField>
                                </div>
                            </div>
                            <div class="row intake-form--row">
                                <div class="col-6 intake-form--column">
                                    <label for="residentialState" class="mr-1">State</label>
                                </div>
                                <div class="col-6 intake-form--column">
                                    <FormTextField name="residentialState" label="" :value="model.residentialState" @field-changed="updateFieldValue" :required="true"></FormTextField>
                                </div>
                            </div>
                            <div class="row intake-form--row">
                                <div class="col-6 intake-form--column">
                                    <label for="residentialPostcode" class="mr-1">Post Code</label>
                                </div>
                                <div class="col-6 intake-form--column">
                                    <FormTextField name="residentialPostcode" label="" :value="model.residentialPostcode" @field-changed="updateFieldValue" :required="true"></FormTextField>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 offset-lg-2">
                            <span class="d-inline-block mb-4">Postal Address (if different from Residential Address)</span>
                            <div class="row intake-form--row">
                                <div class="col-6 intake-form--column">
                                    <label for="postalUnitStreetNumber" class="mr-1">Unit/Street Number</label>
                                </div>
                                <div class="col-6 intake-form--column">
                                    <FormTextField name="postalUnitStreetNumber" label="" :value="model.postalUnitStreetNumber" @field-changed="updateFieldValue" :required="false"></FormTextField>
                                </div>
                            </div>
                            <div class="row intake-form--row">
                                <div class="col-6 intake-form--column">
                                    <label for="postalStreet" class="mr-1">Street</label>
                                </div>
                                <div class="col-6 intake-form--column">
                                    <FormTextField name="postalStreet" label="" :value="model.postalStreet" @field-changed="updateFieldValue" :required="false"></FormTextField>
                                </div>
                            </div>
                            <div class="row intake-form--row">
                                <div class="col-6 intake-form--column">
                                    <label for="postalCity" class="mr-1">City</label>
                                </div>
                                <div class="col-6 intake-form--column">
                                    <FormTextField name="postalCity" label="" :value="model.postalCity" @field-changed="updateFieldValue" :required="false"></FormTextField>
                                </div>
                            </div>
                            <div class="row intake-form--row">
                                <div class="col-6 intake-form--column">
                                    <label for="postalState" class="mr-1">State</label>
                                </div>
                                <div class="col-6 intake-form--column">
                                    <FormTextField name="postalState" label="" :value="model.postalState" @field-changed="updateFieldValue" :required="false"></FormTextField>
                                </div>
                            </div>
                            <div class="row intake-form--row">
                                <div class="col-6 intake-form--column">
                                    <label for="postalPostcode" class="mr-1">Post Code</label>
                                </div>
                                <div class="col-6 intake-form--column">
                                    <FormTextField name="postalPostcode" label="" :value="model.postalPostcode" @field-changed="updateFieldValue" :required="false"></FormTextField>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row intake-form--row mt-5">
                        <div class="col-12 intake-form--column">
                            <span class="mr-4">Is there a Guardianship and/or Administration order in place?</span>
                            <FormRadioGroup :initial-value="model.radios.guardianship" sub-group-name="radios" :supports-custom-text="false" :on-value-select="updateCheckboxValue" group-name="guardianship" :values="['Y', 'N']"></FormRadioGroup>
                        </div>
                    </div>
                    <div class="row intake-form--row">
                        <div class="col-12 intake-form--column">
                            <span class="mr-4">Do you wish to access an advocate?</span>
                            <FormRadioGroup sub-group-name="radios" :initial-value="model.radios.advocate" :supports-custom-text="false" :on-value-select="updateCheckboxValue" group-name="advocate" :values="['Y', 'N']"></FormRadioGroup>
                        </div>
                    </div>
                    <div class="row intake-form--row">
                        <div class="col-12 intake-form--column">
                            <span class="mr-4">Is the participants under the age of 18 years of age, under guardianship, or in the care of family or caregivers?</span>
                            <FormRadioGroup sub-group-name="radios" :initial-value="model.radios.under18" :supports-custom-text="false" :on-value-select="updateCheckboxValue" group-name="under18" :values="['Y', 'N']"></FormRadioGroup>
                        </div>
                    </div>
                    <!-- if Y //-->
                    <div v-if="model.radios.under18 === 'Y'" class="mt-5">
                        <span class="d-inline-block mb-4">Guardian, Caregiver or Advocate details</span>
                        <div class="row intake-form--row">
                            <div class="col-2 intake-form--column">
                                <label for="firstName" class="mr-1">Name</label>
                            </div>
                            <div class="col-3 col-lg-2 intake-form--column">
                                <FormTextField name="guardianFirstName" label="First Name" :value="model.guardianFirstName" @field-changed="updateFieldValue" :required="(model.radios.under18 === 'Y')"></FormTextField>
                            </div>
                            <div class="col-3 col-lg-2 intake-form--column">
                                <FormTextField name="guardianOtherNames" label="Other Names" :value="model.guardianOtherNames" @field-changed="updateFieldValue" :required="false"></FormTextField>
                            </div>
                            <div class="col-3 col-lg-2 intake-form--column">
                                <FormTextField name="guardianLastName" label="Last Name" :value="model.guardianLastName" @field-changed="updateFieldValue" :required="(model.radios.under18 === 'Y')"></FormTextField>
                            </div>
                        </div>
                        <div class="row intake-form--row">
                            <div class="col-12 intake-form--column">
                                <span class="mr-4">Relationship to Participant</span>
                                <FormRadioGroup :initial-value="model.guardianRelationship" :supports-custom-text="true" :custom-text-value="'Other'" :on-value-select="updateCheckboxValue" :large-values="true" group-name="guardianRelationship" :values="['Parent', 'Guardian', 'Caregiver', 'Other']"></FormRadioGroup>
                            </div>
                        </div>
                        <div class="row intake-form--row">
                            <div class="col-12 intake-form--column">
                                <span class="mr-4">Primary Carer?</span>
                                <FormRadioGroup :supports-custom-text="false" :initial-value="model.guardian.guardianPrimaryCarer" sub-group-name="guardian" :on-value-select="updateCheckboxValue" group-name="guardianPrimaryCarer" :values="['Y', 'N']"></FormRadioGroup>
                            </div>
                        </div>
                        <div class="row intake-form--row">
                            <div class="col-12 intake-form--column">
                                <span class="mr-4">Lives with Participant</span>
                                <FormRadioGroup :supports-custom-text="false" sub-group-name="guardian" :initial-value="model.guardian.guardianLivesWithParticipant" :on-value-select="updateCheckboxValue" group-name="guardianLivesWithParticipant" :values="['Y', 'N']"></FormRadioGroup>
                            </div>
                        </div>
                        <div class="row intake-form--row">
                            <div class="col-12 intake-form--column">
                                <span class="mr-4">Emergency Contact</span>
                                <FormRadioGroup :supports-custom-text="false" sub-group-name="guardian" :initial-value="model.guardian.guardianEmergencyContact" :on-value-select="updateCheckboxValue" group-name="guardianEmergencyContact" :values="['Y', 'N']"></FormRadioGroup>
                            </div>
                        </div>
                        <div class="row intake-form--row mt-5">
                            <div class="col-2 intake-form--column">
                                <label for="phone" class="mr-1">Phone</label>
                            </div>
                            <div class="col-3 col-lg-2 intake-form--column">
                                <FormPhoneField name="guardianPhone" label="Phone" :value="model.guardianPhone" @field-changed="updateFieldValue" :required="(model.radios.under18 === 'Y')"></FormPhoneField>
                            </div>
                        </div>
                        <div class="row intake-form--row">
                            <div class="col-2 intake-form--column">
                                <label for="phone" class="mr-1">Email</label>
                            </div>
                            <div class="col-3 col-lg-2 intake-form--column">
                                <FormEmailField name="guardianEmail" label="Email" :value="model.guardianEmail" @field-changed="updateFieldValue" :required="(model.radios.under18 === 'Y')"></FormEmailField>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-lg-5">
                                <span class="d-inline-block mb-4">Residential Address</span>
                                <div class="row intake-form--row">
                                    <div class="col-6 intake-form--column">
                                        <label for="guardianResidentialUnitStreetNumber" class="mr-1">Unit/Street Number</label>
                                    </div>
                                    <div class="col-6 intake-form--column">
                                        <FormTextField name="guardianResidentialUnitStreetNumber" label="" :value="model.guardianResidentialUnitStreetNumber" @field-changed="updateFieldValue" :required="(model.radios.under18 === 'Y')"></FormTextField>
                                    </div>
                                </div>
                                <div class="row intake-form--row">
                                    <div class="col-6 intake-form--column">
                                        <label for="guardianResidentialStreet" class="mr-1">Street</label>
                                    </div>
                                    <div class="col-6 intake-form--column">
                                        <FormTextField name="guardianResidentialStreet" label="" :value="model.guardianResidentialStreet" @field-changed="updateFieldValue" :required="(model.radios.under18 === 'Y')"></FormTextField>
                                    </div>
                                </div>
                                <div class="row intake-form--row">
                                    <div class="col-6 intake-form--column">
                                        <label for="guardianResidentialCity" class="mr-1">City</label>
                                    </div>
                                    <div class="col-6 intake-form--column">
                                        <FormTextField name="guardianResidentialCity" label="" :value="model.guardianResidentialCity" @field-changed="updateFieldValue" :required="(model.radios.under18 === 'Y')"></FormTextField>
                                    </div>
                                </div>
                                <div class="row intake-form--row">
                                    <div class="col-6 intake-form--column">
                                        <label for="guardianResidentialState" class="mr-1">State</label>
                                    </div>
                                    <div class="col-6 intake-form--column">
                                        <FormTextField name="guardianResidentialState" label="" :value="model.guardianResidentialState" @field-changed="updateFieldValue" :required="(model.radios.under18 === 'Y')"></FormTextField>
                                    </div>
                                </div>
                                <div class="row intake-form--row">
                                    <div class="col-6 intake-form--column">
                                        <label for="guardianResidentialPostcode" class="mr-1">Post Code</label>
                                    </div>
                                    <div class="col-6 intake-form--column">
                                        <FormTextField name="guardianResidentialPostcode" label="" :value="model.guardianResidentialPostcode" @field-changed="updateFieldValue" :required="(model.radios.under18 === 'Y')"></FormTextField>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-5 offset-lg-2">
                                <span class="d-inline-block mb-4">Postal Address (if different from Residential Address)</span>
                                <div class="row intake-form--row">
                                    <div class="col-6 intake-form--column">
                                        <label for="guardianPostalUnitStreetNumber" class="mr-1">Unit/Street Number</label>
                                    </div>
                                    <div class="col-6 intake-form--column">
                                        <FormTextField name="guardianPostalUnitStreetNumber" label="" :value="model.guardianPostalUnitStreetNumber" @field-changed="updateFieldValue" :required="false"></FormTextField>
                                    </div>
                                </div>
                                <div class="row intake-form--row">
                                    <div class="col-6 intake-form--column">
                                        <label for="guardianPostalStreet" class="mr-1">Street</label>
                                    </div>
                                    <div class="col-6 intake-form--column">
                                        <FormTextField name="guardianPostalStreet" label="" :value="model.guardianPostalStreet" @field-changed="updateFieldValue" :required="false"></FormTextField>
                                    </div>
                                </div>
                                <div class="row intake-form--row">
                                    <div class="col-6 intake-form--column">
                                        <label for="guardianPostalCity" class="mr-1">City</label>
                                    </div>
                                    <div class="col-6 intake-form--column">
                                        <FormTextField name="guardianPostalCity" label="" :value="model.guardianPostalCity" @field-changed="updateFieldValue" :required="false"></FormTextField>
                                    </div>
                                </div>
                                <div class="row intake-form--row">
                                    <div class="col-6 intake-form--column">
                                        <label for="guardianPostalState" class="mr-1">State</label>
                                    </div>
                                    <div class="col-6 intake-form--column">
                                        <FormTextField name="guardianPostalState" label="" :value="model.guardianPostalState" @field-changed="updateFieldValue" :required="false"></FormTextField>
                                    </div>
                                </div>
                                <div class="row intake-form--row">
                                    <div class="col-6 intake-form--column">
                                        <label for="guardianPostalPostcode" class="mr-1">Post Code</label>
                                    </div>
                                    <div class="col-6 intake-form--column">
                                        <FormTextField name="guardianPostalPostcode" label="" :value="model.guardianPostalPostcode" @field-changed="updateFieldValue" :required="false"></FormTextField>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row intake-form--row">
                        <div class="col-12 intake-form--column">
                            <span class="mr-4">How did you hear about us?</span>
                            <FormRadioGroup sub-group-name="radios" :initial-value="model.radios.howDidYouHearAboutUs" :large-values="true" :supports-custom-text="false" :tall-values="true" :on-value-select="updateCheckboxValue" group-name="howDidYouHearAboutUs" :values="['LAC referral', 'Service Provider referral', 'Participant referral', 'Social Media', 'Other']"></FormRadioGroup>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-section bg-pinkDark">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h4 class="intake-form--title">3. Optional Preferences</h4>
                        </div>
                    </div>
                    <div class="row intake-form--row">
                        <div class="col-2 intake-form--column">
                            <label for="preferredName" class="mr-1">Preferred Name</label>
                        </div>
                        <div class="col-lg-6 col-10 intake-form--column">
                            <FormTextField name="preferredName" label="" :value="model.preferredName" @field-changed="updateFieldValue" :required="false"></FormTextField>
                        </div>
                    </div>
                    <div class="row intake-form--row">
                        <div class="col-2 intake-form--column align-items-start">
                            <label for="religiousRequirements" class="mr-1">Religious Requirements</label>
                        </div>
                        <div class="col-lg-10 col-10 intake-form--column">
                            <FormTextField type="textarea" name="religiousRequirements" label="" :value="model.religiousRequirements" @field-changed="updateFieldValue" :required="false"></FormTextField>
                        </div>
                    </div>
                    <div class="row intake-form--row">
                        <div class="col-2 intake-form--column align-items-start">
                            <label for="culturalRequirements" class="mr-1">Cultural Requirements</label>
                        </div>
                        <div class="col-lg-10 col-10 intake-form--column">
                            <FormTextField type="textarea" name="culturalRequirements" label="" :value="model.culturalRequirements" @field-changed="updateFieldValue" :required="false"></FormTextField>
                        </div>
                    </div>
                    <div class="row intake-form--row">
                        <div class="col-2 intake-form--column align-items-start">
                            <label for="communicationDevice" class="mr-1">Communication Device</label>
                        </div>
                        <div class="col-lg-10 col-10 intake-form--column">
                            <FormTextField type="textarea" name="communicationDevice" label="" :value="model.communicationDevice" @field-changed="updateFieldValue" :required="false"></FormTextField>
                        </div>
                    </div>
                    <div class="row intake-form--row">
                        <div class="col-2 intake-form--column align-items-start">
                            <label for="physicalAssistance" class="mr-1">Physical Assistance</label>
                        </div>
                        <div class="col-lg-10 col-10 intake-form--column">
                            <FormTextField type="textarea" name="physicalAssistance" label="" :value="model.physicalAssistance" @field-changed="updateFieldValue" :required="false"></FormTextField>
                        </div>
                    </div>
                    <div class="row intake-form--row">
                        <div class="col-2 intake-form--column align-items-start">
                            <label for="otherConsiderations" class="mr-1">Other Considerations</label>
                        </div>
                        <div class="col-lg-10 col-10 intake-form--column">
                            <FormTextField type="textarea" name="otherConsiderations" label="" :value="model.otherConsiderations" @field-changed="updateFieldValue" :required="false"></FormTextField>
                        </div>
                    </div>
                </div>
            </div>
        <div class="page-section bg-white">
            <div class="container">
                <div class="row">
                    <div class="col-12 intake-form--content" v-html="component.fields.declarationContent">
                    </div>
                </div>
            </div>
        </div>
        <div class="page-section bg-purpleMain">
            <div class="container">
                <div class="row intake-form--row">
                    <div class="col-9 intake-form--column">
                        <span class="d-inline-block intake-form--label-large">To the best of my knowledge, the information provided in this form is true and correct</span>
                    </div>
                    <div class="col-3 intake-form--column justify-content-end">
                        <FormRadioGroup :initial-value="model.trueAndCorrect" :title-values="true" :supports-custom-text="false" :on-value-select="updateCheckboxValue" group-name="trueAndCorrect" :values="['Y', 'N']"></FormRadioGroup>
                    </div>
                </div>
                <div class="row intake-form--row">
                    <div class="col-9 intake-form--column">
                        <span class="d-inline-block intake-form--label-large">I will digital sign for these details in the last form</span>
                    </div>
                    <div class="col-3 intake-form--column justify-content-end">
                        <FormRadioGroup :initial-value="model.willDigitallySign" :title-values="true" :supports-custom-text="false" :on-value-select="updateCheckboxValue" group-name="willDigitallySign" :values="['Y', 'N']"></FormRadioGroup>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-12 d-flex align-items-center">
                        <div :class="'intake-form--status'+(!isValid ? ' is-disabled' : '')" v-html="(!isValid ? ' Still some required responses above' : '')"></div>
                        <input type="submit" :disabled="!(isValid && submitting === false)" :class="'intake-form--between-page'+(!isValid || submitting ? ' is-disabled' : '')" value="Next"/>
                    </div>
                </div>
            </div>
        </div>
        </form>
        <FormSaver :current-data="model" current-position="1" :csrf-name="csrfName" :csrf-value="csrfValue" />
    </div>
</template>

<script>
    import FormPagePosition from "./FormPagePosition";
    import FormSaver from './FormSaver';
    import ky from 'ky';

    export default {
        components: {FormPagePosition, FormSaver},
        computed: {
            submitData() {
                let data = {...this.model};
                data[this.csrfName] = this.csrfValue;
                return data;
            },
            isValid() {
                var isValid = true;

                // dont bother if any child fields are invalid
                if (this.currentlyInvalidFields.length > 0) {
                    isValid = false;
                } else {
                    if (this.model.communication === null) {
                        isValid = false;
                    }

                    if (this.model.gender === null) {
                        isValid = false;
                    } else {
                        if (this.model.gender === 'Other') {
                            isValid = false;
                        }
                    }

                    for (var key in this.model.radios) {
                        if (this.model.radios.hasOwnProperty(key)) {
                            if (this.model.radios[key] === null) {
                                isValid = false;
                            }
                        }
                    }


                    // potentially required (if under 18)
                    if (this.model.radios.under18 === 'Y') {
                        for (var key in this.model.guardian) {
                            // check also if property is not inherited from prototype
                            if (this.model.guardian.hasOwnProperty(key)) {
                                if (this.model.guardian[key] === null) {
                                    isValid = false;
                                }
                            }
                        }

                        if (this.model.guardianRelationship === null) {
                            isValid = false;
                        } else {
                            if (this.model.guardianRelationship === 'Other') {
                                isValid = false;
                            }
                        }
                    }

                    //
                    if (this.model.trueAndCorrect !== 'Y' || this.model.willDigitallySign !== 'Y') {
                        isValid = false;
                    }
                }

                return isValid;
            }
        },
        data() {
            return {
                submitting: false,
                currentlyInvalidFields: [],
                model: {
                    ndisNumber: null,
                    ndisDateDay: null,
                    ndisDateMonth: null,
                    ndisDateYear: null,
                    ndisContact: null,
                    ndisPlan: null,
                    firstName: null,
                    otherNames: null,
                    lastName: null,
                    dobDay: null,
                    dobMonth: null,
                    dobYear: null,
                    gender: null,
                    language: null,
                    phone: null,
                    email: null,
                    communication: null,
                    residentialUnitStreetNumber: null,
                    residentialStreet: null,
                    residentialCity: null,
                    residentialState: null,
                    residentialPostcode: null,
                    postalUnitStreetNumber: null,
                    postalStreet: null,
                    postalCity: null,
                    postalState: null,
                    postalPostcode: null,
                    guardianFirstName: null,
                    guardianOtherNames: null,
                    guardianLastName: null,
                    guardianPhone: null,
                    guardianEmail: null,
                    radios: {
                        planManaged: null,
                        interpreter: null,
                        atsi: null,
                        guardianship: null,
                        advocate: null,
                        under18: null,
                        howDidYouHearAboutUs: null
                    },
                    guardianRelationship: null,
                    guardian: {
                        guardianPrimaryCarer: null,
                        guardianLivesWithParticipant: null,
                        guardianEmergencyContact: null,
                    },
                    guardianResidentialUnitStreetNumber: null,
                    guardianResidentialStreet: null,
                    guardianResidentialCity: null,
                    guardianResidentialState: null,
                    guardianResidentialPostcode: null,
                    guardianPostalUnitStreetNumber: null,
                    guardianPostalStreet: null,
                    guardianPostalCity: null,
                    guardianPostalState: null,
                    guardianPostalPostcode: null,
                    preferredName: null,
                    religiousRequirements: null,
                    culturalRequirements: null,
                    communicationDevice: null,
                    physicalAssistance: null,
                    otherConsiderations: null,
                    trueAndCorrect: null,
                    willDigitallySign: null,
                }
            };
        },
        props: ['component', 'formPage1', 'formPage1SubmitUrl', 'csrfName', 'csrfValue'],
        created() {
            if (this.formPage1) {
                this.model = JSON.parse(this.formPage1);
            }
        },
        methods: {
            updateCheckboxValue(groupName, subGroupName, value) {
                if (subGroupName) {
                    this.model[subGroupName][groupName] = value;
                } else {
                    this.model[groupName] = value;
                }
            },
            updateFieldValue(name, value, isValid) {
                this.model[name] = value;
                if (isValid && this.currentlyInvalidFields.includes(name)) {
                    var index = this.currentlyInvalidFields.indexOf(name);
                    if (index > -1) {
                        this.currentlyInvalidFields.splice(index, 1);
                    }
                } else if (!isValid && !this.currentlyInvalidFields.includes(name)) {
                    this.currentlyInvalidFields.push(name);
                }
            },
            afterFormSuccess(data) {
                this.submitting = false;
                window.location = this.component.fields.formPage2Url;
            },
            afterFormError(err) {
                this.submitting = false;
            },
            submitForm(e) {
                if (e) {
                    e.preventDefault();
                }

                this.submitting = true;

                if (!this.isValid) {
                    this.submitting = false;
                    return false;
                } else {
                    ky.post('/formpage1submit', {json: this.submitData}).json().then(this.afterFormSuccess, this.afterFormError);
                }
            }
        }
    }
</script>
