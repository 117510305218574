<template>
    <div class="page-section content-grid" :id="component.fields.sectionId">
        <div class="container">
            <div class="row">
                <div class="offset-md-1 col-md-3">
                    <h4 v-html="f.gridTitle" :style="`color:${f.gridTitleColour};`"></h4>
                </div>
                <div class="col-md-8 content-grid-rows">
                    <div v-for="row in batch(f.gridItems, f.gridItemsPerRow)" class="row">
                        <div v-for="column in row" :class="columnClass" class="content-grid-content-item">
                            <h6 v-html="column.fields.itemTitle" :style="`color:${column.fields.itemTitleColour};`"></h6>
                            <p v-html="column.fields.itemContent" :style="`color:${column.fields.itemContentColour};`"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['entry', 'component', 'footer', 'header', 'currentslug', 'csrfName', 'csrfValue', 'componentKey'],
        computed: {
            f() {
                return this.component.fields;
            },
            columnClass() {
                if (this.f.gridItemsPerRow < 1 || this.f.gridItemsPerRow > 6) {
                    return 'col-md-2';
                }

                return 'col-md-' + Math.floor(12 / this.f.gridItemsPerRow)
            }
        },
        methods: {
            batch(items, batchSize) {
                let result = [];
                let data = items.slice(0);
                while (data[0]) {
                    result.push(data.splice(0, batchSize));
                }
                return result;
            }
        }
    }
</script>
