<template>
    <span class="w-100">
        <input :class="'intake-form--input'+(isInvalid ? ' is-invalid' : '')" type="file" :id="name + '_uploader'" :name="name + '_uploader'" v-on:change="convertFile" :placeholder="label"/>
        <input type="hidden" :id="name" :name="name" v-model="inputValue" :placeholder="label"/>
        <span v-if="required && isInvalid" class="text-danger">*Required</span>
        <span class="mt-2 d-block position-static" v-if="fileName"><span class="text-bold">Uploading</span>: <em>{{ fileName }}</em></span>
    </span>
</template>

<script>
    import _ from 'lodash';

    export default {
        data() {
            return {
                inputValue: this.value,
                fileName: null,
                fileType: null
            }
        },
        created() {
            if (this.inputValue !== null) {
                if (this.inputValue.charAt(0) === '~') {
                    var modifiedValue = this.inputValue.substring(1);
                    var splitValues = modifiedValue.split('+|+');
                    this.fileName = splitValues[0];
                    this.fileType = splitValues[1];
                }
            }
        },
        computed: {
            isInvalid() {
                if (this.required && (this.inputValue === null || this.inputValue === '')) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        methods: {
            convertFile(event) {
                var file = event.srcElement.files[0];
                var component = this;
                var reader = new FileReader();
                reader.readAsBinaryString(file);

                reader.onload = function() {
                    component.fileType = file.type;
                    component.fileName = file.name;
                    component.inputValue = '~' + file.name + '+|+' + file.type + '+|+' + btoa(reader.result);
                };
            }
        },
        props: ['type', 'value', 'label', 'name', 'required'],
        watch: {
            inputValue(val) {
                this.$emit('field-changed', this.name, val, !(this.isInvalid));
            }
        }
    }
</script>