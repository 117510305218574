<template>
    <span class="group-parent">
        <span :class="'radio-parent'+(tallValues ? ' has-tall-values' : '')+(largeValues ? ' has-large-values' : '')+(titleValues ? ' has-title-values' : '')" v-for="(value, key) in values">
            <input type="radio" :id="groupName + key" :name="groupName" :value="value" v-on:click="toggledValue" :checked="initialValue === value ? 'checked' : ''">
            <label :for="groupName">
                <span v-html="value"></span>
            </label>
        </span>
        <input :class="'intake-form--input w-30'+(shouldShowCustomText && this.customText === null ? ' is-invalid' : '')" placeholder="Enter your custom value" :name="groupName" type="text" v-if="shouldShowCustomText" v-on:change="updatedCustomValue"/>
    </span>
</template>

<script>
    export default {
        computed: {
            shouldShowCustomText() {
                return this.supportsCustomText && (this.currentValue === this.customTextValue || this.customText !== null);
            }
        },
        data() {
            return {
                currentValue: null,
                customText: null
            }
        },
        props: ['supportsCustomText', 'customTextValue', 'onValueSelect', 'groupName', 'subGroupName', 'values', 'largeValues', 'tallValues', 'titleValues', 'initialValue'],
        methods: {
            toggledValue(e) {
                this.currentValue = e.target.value;
                this.customText = null;
                this.onValueSelect(this.groupName, this.subGroupName, e.target.value);
            },
            updatedCustomValue(e) {
                this.currentValue = e.target.value;
                this.customText = e.target.value;
                this.onValueSelect(this.groupName, this.subGroupName, e.target.value);
            }
        }
    }
</script>