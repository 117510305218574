<template>
    <div class="page-section columns-section" :id="component.fields.sectionId" :style="sectionStyle">
        <div :class="containerClass">
            <div class="row column-row">
                <div :class="[f.leftColumnWidth, 'column']" :style="leftColumnStyles">
                    <img v-if="f.leftColumnImage.length" :src="f.leftColumnImage[0]" class="column-image" />
                    <div v-if="f.leftColumnContent" v-html="f.leftColumnContent"></div>
                    <div v-if="f.leftColumnButtonUrl && f.leftColumnButtonText">
                        <a class="btn btn-primary" :href="f.leftColumnButtonUrl" v-html="f.leftColumnButtonText" :target="f.leftColumnButtonOpenInNewTab && '_blank'"></a>
                    </div>
                </div>
                <div :class="[f.rightColumnWidth, 'column']" :style="rightColumnStyles">
                    <img v-if="f.rightColumnImage.length" :src="f.rightColumnImage[0]" class="column-image"  />
                    <div v-if="f.rightColumnContent" v-html="f.rightColumnContent"></div>
                    <div v-if="f.rightColumnButtonUrl && f.rightColumnButtonText">
                        <a class="btn btn-primary" :href="f.rightColumnButtonUrl" v-html="f.rightColumnButtonText" :target="f.rightColumnButtonOpenInNewTab && '_blank'"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['entry', 'component', 'footer', 'header', 'currentslug', 'csrfName', 'csrfValue', 'noBackground', 'componentKey'],
        computed: {
            f() {
                return this.component.fields;
            },
            containerClass() {
                let classes = ['d-flex', 'align-items-center', 'h-100'];
                classes.push(this.f.widthConstrained ? 'container' : 'container-fluid');
                return classes;
            },
            sectionStyle() {
                let style = '';

                if (!this.noBackground) {
                    style += `background-color: ${this.f.backgroundColour};` ;

                    if (this.f.backgroundImage.length) {
                        style += `background-image: url(${this.f.backgroundImage[0]});`;
                    }
                }

                if (!this.f.automaticHeight) {
                    style += `height: ${this.f.columnsHeight}vh;`
                }

                return style;
            },
            leftColumnStyles() {
                let style = '';
                if (this.f.leftColumnBackgroundColour) {
                    style += `background-color: ${this.f.leftColumnBackgroundColour};`
                }
                return style;
            },
            rightColumnStyles() {
                let style = '';
                if (this.f.rightColumnBackgroundColour) {
                    style += `background-color: ${this.f.rightColumnBackgroundColour};`
                }
                return style;
            },
        }
    }
</script>
