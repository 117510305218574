<template>
    <div>
        <FormPagePosition :current-position="4" :back-url="component.fields.formPage3Url" :allow-next="false" @next="submitForm" />
        <div class="page-section bg-white">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h1 class="form-title" v-html="component.fields.finalCheckListTitle"></h1>
                        <p class="form-subtitle">This checklist is to be checked and signed by participant to confirm that they have the knowledge and understanding of Dawson Plan Managers processes and procedures.</p>
                    </div>
                </div>
            </div>
        </div>
        <form method="POST" v-on:submit.prevent="submitForm" :action="component.fields.thankyouUrl" class="intake-form">
            <div class="page-section bg-tanDark">
                <div class="container">
                    <div class="row intake-form--row">
                        <div class="col-9 intake-form--column">
                            <span class="d-inline-block intake-form--label-large greyDark">The participant handbook will be emailed to {{ email }}</span>
                        </div>
                        <div class="col-3 intake-form--column justify-content-end">
                            <FormRadioGroup :initial-value="model.emailHandbook" :title-values="true" :supports-custom-text="false" :on-value-select="updateCheckboxValue" group-name="emailHandbook" :values="['Y', 'N']"></FormRadioGroup>
                        </div>
                    </div>
                    <div class="row intake-form--row">
                        <div class="col-9 intake-form--column">
                            <span class="d-inline-block intake-form--label-large greyDark">I understand this handbook includes:<br><br>
-  Rights and responsibilities, including:<br>
       •    how to exercise rights<br>
       •    able to have an advocate of choice<br>
       •    interpreter access<br><br>
-  Complaints and feedback procedure:<br>
       •    How to make a complaint<br>
       •    How to give feedback<br><br>
-  Information about the Dawson Plan Managers including:<br>
       •    operating hours and locations<br>
       •    our services<br>
       •    Point of contact – who to contact, contact details</span>
                        </div>
                        <div class="col-3 intake-form--column justify-content-end align-items-end">
                            <FormRadioGroup :initial-value="model.handbookIncludes" :title-values="true" :supports-custom-text="false" :on-value-select="updateCheckboxValue" group-name="handbookIncludes" :values="['Y', 'N']"></FormRadioGroup>
                        </div>
                    </div>
                    <div class="row intake-form--row">
                        <div class="col-9 intake-form--column">
                            <span class="d-inline-block intake-form--label-large greyDark">I have read over the Service Agreement and the following signing confirms this. A signed PDF version of the form will be emailed to {{ email }} and Dawson Plan Managers</span>
                        </div>
                        <div class="col-3 intake-form--column justify-content-end">
                            <FormRadioGroup :initial-value="model.confirmServiceAgreement" :title-values="true" :supports-custom-text="false" :on-value-select="updateCheckboxValue" group-name="confirmServiceAgreement" :values="['Y', 'N']"></FormRadioGroup>
                        </div>
                    </div>
                    <div class="row intake-form--row">
                        <div class="col-9 intake-form--column">
                            <span class="d-inline-block intake-form--label-large greyDark">I have read over the Consent Form and the following signing confirms this. A signed PDF version of the form will be emailed to {{ email }} and Dawson Plan Managers</span>
                        </div>
                        <div class="col-3 intake-form--column justify-content-end">
                            <FormRadioGroup :initial-value="model.confirmConsentForm" :title-values="true" :supports-custom-text="false" :on-value-select="updateCheckboxValue" group-name="confirmConsentForm" :values="['Y', 'N']"></FormRadioGroup>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-section bg-purpleMain">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <p><span class="d-inline-block intake-form--label-large">Dawson Plan Managers has informed me of policies and procedures relevant to my support plan.  I understand my rights and responsibilities during my time with this organisation.</span></p>
                            <p class="mt-5"><span class="d-inline-block intake-form--label-large">Dawson Plan Managers has informed me of policies and procedures relevant to my support plan.  I understand my rights and responsibilities during my time with this organisation.</span></p>
                            <p class="mt-5 mb-3"><span class="d-inline-block intake-form--label-large">Please draw your signature here, with a mouse or touch</span></p>
                            <VueSignaturePad ref="signaturePad" height="200px" :options="{backgroundColor:'rgba(255,255,255,1)'}"/>
                            <button class="btn-signature mt-2" @click="clear">Clear</button>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-12 d-flex align-items-center">
                            <a class="intake-form--between-page" :href="component.fields.formPage3Url">Back</a>
                            <div :class="'intake-form--status'+(!isValid ? ' is-disabled' : '')" v-html="(!isValid ? ' Still some required responses above' : '')"></div>
                            <input type="submit" :disabled="!(isValid && submitting === false)" :class="'intake-form--between-page'+(!isValid || submitting ? ' is-disabled' : '')" value="Submit"/>
                        </div>
                    </div>
                    <div class="row" v-if="submitMessage">
                        <div class="col-12 mt-3">
                            <div class="submit-message" :class="submitError && 'submit-errors form-control alert-warning'">
                                <span v-html="submitMessage"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <FormSaver :current-data="model" current-position="4" :csrf-name="csrfName" :csrf-value="csrfValue" />
    </div>
</template>

<script>
    import FormPagePosition from "./FormPagePosition";
    import FormSaver from "./FormSaver";
    import ky from 'ky';

    export default {
        components: {FormPagePosition, FormSaver},
        computed: {
            submitData() {
                let data = {...this.model};
                data[this.csrfName] = this.csrfValue;
                return data;
            },
            isValid() {
                var isValid = true;


                // dont bother if any child fields are invalid
                if (this.currentlyInvalidFields.length > 0) {
                    isValid = false;
                } else {
                    if (this.$refs.signaturePad) {
                        if (this.$refs.signaturePad.isEmpty()) {
                            isValid = false;
                        }
                    }

                    if (this.model.emailHandbook !== 'Y' || this.model.handbookIncludes !== 'Y' || this.model.confirmServiceAgreement !== 'Y' || this.model.confirmConsentForm !== 'Y') {
                        isValid = false;
                    }
                }

                return isValid;
            }
        },
        data() {
            return {
                submitting: false,
                currentlyInvalidFields: [],
                model: {
                    emailHandbook: null,
                    handbookIncludes: null,
                    confirmServiceAgreement: null,
                    confirmConsentForm: null,
                    signature: null
                },
                email: null,
                submitMessage: '',
                submitError: false
            };
        },
        props: ['component', 'formPage4', 'formPage1', 'formPage2', 'formPage3', 'formPage4SubmitUrl', 'csrfName', 'csrfValue'],
        created() {
            if (!this.formPage1 || !this.formPage2 || !this.formPage3) {
                window.location = this.component.fields.formPage3Url;
            }

            this.email = JSON.parse(this.formPage1).email;
            if (this.formPage4) {
                this.model = JSON.parse(this.formPage4);
            }
        },
        methods: {
            clear() {
                this.$refs.signaturePad.clearSignature();
            },
            updateCheckboxValue(groupName, subGroupName, value) {
                if (subGroupName) {
                    this.model[subGroupName][groupName] = value;
                } else {
                    this.model[groupName] = value;
                }
            },
            updateFieldValue(name, value, isValid) {
                this.model[name] = value;
                if (isValid && this.currentlyInvalidFields.includes(name)) {
                    var index = this.currentlyInvalidFields.indexOf(name);
                    if (index > -1) {
                        this.currentlyInvalidFields.splice(index, 1);
                    }
                } else if (!isValid && !this.currentlyInvalidFields.includes(name)) {
                    this.currentlyInvalidFields.push(name);
                }
            },
            afterFormSuccess(data) {
                this.submitting = false;
                if (data.success) {
                    window.location = this.component.fields.thankyouUrl;
                } else {
                    this.submitMessage = data.formErrors;
                    this.submitError = true;
                }
            },
            afterFormError(err) {
                this.submitting = false;
                this.submitError = true;
                this.submitMessage = err.message || 'An unknown error occurred, please try again.';
            },
            submitForm(e) {
                if (e) {
                    e.preventDefault();
                }

                this.model.signature = this.$refs.signaturePad.saveSignature();
                this.submitting = true;
                this.submitError = false;
                this.submitMessage = null;

                if (!this.isValid) {
                    this.submitting = false;
                    return false;
                } else {
                    ky.post('/formpage4submit', {json: this.submitData}).json().then(this.afterFormSuccess, this.afterFormError);
                }
            }
        }
    }
</script>
