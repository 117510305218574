<template>
    <div class="page-section form-holder-section" :id="f.sectionId" :style="sectionStyles">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <h3 v-html="f.formTitle" :style="`color: ${f.formTitleColour};`"></h3>
                    <p v-html="nl2br(f.formContent)" :style="`color: ${f.formContentColour};`"></p>
                    <a v-if="f.phoneNumber" :href="'tel:'+f.phoneNumber.split(' ').join('')">
                        <h3 :style="`color: ${f.formContentColour};`">
                            <i class="fa fa-phone fa-rotate-270"></i>
                            <span v-html="f.phoneNumber"></span>
                        </h3>
                    </a>
                </div>
                <div class="col-md-6 offset-md-2">
                    <contact-form :csrf-name="csrfName" :csrf-value="csrfValue"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['entry', 'component', 'footer', 'header', 'noBackground', 'currentslug', 'csrfName', 'csrfValue', 'componentKey'],
        computed: {
            f() {
                return this.component.fields;
            },
            sectionStyles() {
                if (this.noBackground) {
                    return '';
                }

                let styles = '';

                if (this.f.backgroundColour) {
                    styles += `background-color: ${this.f.backgroundColour};`;
                }
                if (this.f.backgroundImage.length) {
                    styles += `background-image: url(${this.f.backgroundImage[0]});`;
                }
                return styles;
            }
        }
    }
</script>
