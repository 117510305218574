<template>
    <span class="w-100">
        <input :class="'intake-form--input'+(isInvalid ? ' is-invalid' : '')" type="text" :id="name" :name="name" v-model="inputValue" :placeholder="label"/>
        <span v-if="required && isInvalid" class="text-danger">*Required</span>
    </span>
</template>

<script>
    export default {
        data() {
            return {
                inputValue: this.value
            }
        },
        computed: {
            isInvalid() {
                if (this.required && (this.inputValue === null || this.inputValue === '')) {
                    return true;
                }

                if (this.inputValue.length > 0) {
                    return !(new RegExp('(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])').test(this.inputValue));
                } else {
                    return false;
                }
            }
        },
        props: ['type', 'value', 'label', 'name', 'required'],
        watch: {
            inputValue(val) {
                this.$emit('field-changed', this.name, val, !(this.isInvalid));
            }
        }
    }
</script>