<template>
    <div class="container blowout bg-tanDark position-block" id="formPage1-blowout">
        <div class="blowout-column-inner">
            <div class="row">
                <div class="col-12">
                    <p class="position-blurb">There are four documents over four pages to be digitally signed off: </p>
                </div>
            </div>
            <div class="row position-row">
                <div class="col-3">
                    <div v-if="currentPosition >= 1" :class="'position-indicator'+(currentPosition > 1 ? ' previous' : '')" v-html="currentPosition > 1 ? 'Complete' : 'You are here'"></div>
                </div>
                <div class="col-9 position-info">
                    <h3>1. Participant Intake Form</h3>
                    <p>All the information about you or your participant.</p>
                </div>
            </div>

            <div class="row position-row">
                <div class="col-3">
                    <div v-if="currentPosition > 1" :class="'position-indicator'+(currentPosition > 2 ? ' previous' : '')" v-html="currentPosition > 2 ? 'Complete' : 'You are here'"></div>
                </div>
                <div class="col-9 position-info">
                    <h3>2. Service Agreement Form</h3>
                    <p>Information about what Dawson Plan Managers has permission to do.</p>
                </div>
            </div>


            <div class="row position-row">
                <div class="col-3">
                    <div v-if="currentPosition > 2" :class="'position-indicator'+(currentPosition > 3 ? ' previous' : '')" v-html="currentPosition > 3 ? 'Complete' : 'You are here'"></div>
                </div>
                <div class="col-9 position-info">
                    <h3>3. Participant Information Consent Form</h3>
                    <p>Giving authority for Dawson Plan Managers to collect, hold, use and disclose participant information.</p>
                </div>
            </div>


            <div class="row position-row">
                <div class="col-3">
                    <div v-if="currentPosition > 3" class="position-indicator">You are here</div>
                </div>
                <div class="col-9 position-info">
                    <h3>4. Final Check List and Signing</h3>
                    <p>A list of all the forms and information that will be sent to you, and the digital signing.</p>
                </div>
            </div>
        </div>

        <FormNavigation :back-url="backUrl" :allow-next="allowNext" @next="$emit('next')" />
    </div>
</template>

<script>
    import FormNavigation from "./FormNavigation";
    export default {
        components: {FormNavigation},
        props: ['currentPosition', 'backUrl', 'allowNext']
    }
</script>
