<template>
    <div class="page-section icon-grid-section" :id="f.sectionId" :style="`background-color: ${f.backgroundColour};`">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center mb-3">
                    <h3 v-html="f.gridTitle" :style="`color:${f.gridTitleColour};`"></h3>
                </div>
            </div>
            <div class="icon-grid-row">
                <div v-for="item in f.gridItems" class="icon-grid-item" :style="`width: ${itemWidth}%;`">
                    <img v-if="item.fields.itemIcon" :src="item.fields.itemIcon[0]" />
                    <h6 v-html="item.fields.itemTitle" :style="`color: ${item.fields.itemTitleColour};`"></h6>
                    <p v-html="item.fields.itemContent" :style="`color: ${item.fields.itemContentColour};`"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['entry', 'component', 'footer', 'header', 'currentslug', 'csrfName', 'csrfValue', 'componentKey'],
        computed: {
            f() {
                return this.component.fields;
            },
            itemWidth() {
                if (!this.f.gridItemsPerRow) {
                    return 0;
                }

                return (1 / this.f.gridItemsPerRow) * 100;
            }
        }
    }
</script>
