<template>
    <div>
        <FormPagePosition :current-position="2" :back-url="component.fields.formPage1Url" :allow-next="isValid" @next="submitForm" />
        <div class="page-section bg-white">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h1 class="form-title" v-html="component.fields.serviceAgreementTitle"></h1>
                    </div>
                </div>
            </div>
        </div>
        <form method="POST" v-on:submit.prevent="submitForm" :action="component.fields.formPage3Url" class="intake-form">
                        <div class="page-section bg-pinkDark">
                <div class="container">
                    <div class="row">
                        <div class="col-12" v-html="component.fields.section1Content">
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-section bg-tanDark">
                <div class="container">
                    <div class="row">
                        <div class="col-12" v-html="component.fields.section2Content">
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-section bg-pinkDark">
                <div class="container">
                    <div class="row">
                        <div class="col-12" v-html="component.fields.section3Content">
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-section bg-tanDark">
                <div class="container">
                    <div class="row">
                        <div class="col-12" v-html="component.fields.section4Content">
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-section bg-purpleMain">
                <div class="container">
                    <div class="row intake-form--row">
                        <div class="col-9 intake-form--column">
                            <span class="d-inline-block intake-form--label-large">I have read and understand the information above</span>
                        </div>
                        <div class="col-3 intake-form--column justify-content-end">
                            <FormRadioGroup :initial-value="model.readAndUnderstood" :title-values="true" :supports-custom-text="false" :on-value-select="updateCheckboxValue" group-name="readAndUnderstood" :values="['Y', 'N']"></FormRadioGroup>
                        </div>
                    </div>
                    <div class="row intake-form--row">
                        <div class="col-9 intake-form--column">
                            <span class="d-inline-block intake-form--label-large">I will digital sign for these details in the last form</span>
                        </div>
                        <div class="col-3 intake-form--column justify-content-end">
                            <FormRadioGroup :initial-value="model.willDigitallySign" :title-values="true" :supports-custom-text="false" :on-value-select="updateCheckboxValue" group-name="willDigitallySign" :values="['Y', 'N']"></FormRadioGroup>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-12 d-flex align-items-center">
                            <a class="intake-form--between-page" :href="component.fields.formPage1Url">Back</a>
                            <div :class="'intake-form--status'+(!isValid ? ' is-disabled' : '')" v-html="(!isValid ? ' Still some required responses above' : '')"></div>
                            <input type="submit" :disabled="!(isValid && submitting === false)" :class="'intake-form--between-page'+(!isValid || submitting ? ' is-disabled' : '')" value="Next"/>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <FormSaver :current-data="model" current-position="2" :csrf-name="csrfName" :csrf-value="csrfValue" />
    </div>
</template>

<script>
    import FormPagePosition from "./FormPagePosition";
    import FormSaver from "./FormSaver";
    import ky from 'ky';

    export default {
        components: {FormPagePosition, FormSaver},
        computed: {
            submitData() {
                let data = {...this.model};
                data[this.csrfName] = this.csrfValue;
                return data;
            },
            isValid() {
                var isValid = true;

                // dont bother if any child fields are invalid
                if (this.currentlyInvalidFields.length > 0) {
                    isValid = false;
                } else {
                    if (this.model.readAndUnderstood !== 'Y' || this.model.willDigitallySign !== 'Y') {
                        isValid = false;
                    }
                }

                return isValid;
            }
        },
        data() {
            return {
                submitting: false,
                currentlyInvalidFields: [],
                model: {
                    readAndUnderstood: null,
                    willDigitallySign: null,
                }
            };
        },
        props: ['component', 'formPage1', 'formPage2', 'formPage2SubmitUrl', 'csrfName', 'csrfValue'],
        created() {
            if (!this.formPage1) {
                window.location = this.component.fields.formPage1Url;
            }
            if (this.formPage2) {
                this.model = JSON.parse(this.formPage2);
            }
        },
        methods: {
            updateCheckboxValue(groupName, subGroupName, value) {
                if (subGroupName) {
                    this.model[subGroupName][groupName] = value;
                } else {
                    this.model[groupName] = value;
                }
            },
            updateFieldValue(name, value, isValid) {
                this.model[name] = value;
                if (isValid && this.currentlyInvalidFields.includes(name)) {
                    var index = this.currentlyInvalidFields.indexOf(name);
                    if (index > -1) {
                        this.currentlyInvalidFields.splice(index, 1);
                    }
                } else if (!isValid && !this.currentlyInvalidFields.includes(name)) {
                    this.currentlyInvalidFields.push(name);
                }
            },
            afterFormSuccess(data) {
                this.submitting = false;
                window.location = this.component.fields.formPage3Url;
            },
            afterFormError(err) {
                this.submitting = false;
            },
            submitForm(e) {
                if (e) {
                    e.preventDefault();
                }

                this.submitting = true;

                if (!this.isValid) {
                    this.submitting = false;
                    return false;
                } else {
                    ky.post('/formpage2submit', {json: this.submitData}).json().then(this.afterFormSuccess, this.afterFormError);
                }
            }
        }
    }
</script>
