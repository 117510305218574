<template>
    <div class="form-navigation">
        <a :href="backUrl" v-if="backUrl" class="btn btn-primary">Back</a>
        <a href="#" v-if="allowNext" @click.prevent="$emit('next')" class="btn btn-primary">Next</a>
    </div>
</template>

<script>
    export default {
        props: ['backUrl', 'allowNext']
    }
</script>
