<template>
    <div class="container blowout" :id="component.fields.sectionId" :style="'background-color: ' + component.fields.backgroundColour + ';'">
        <div class="row" v-if="component.fields.additionalTopContent">
            <div class="col-12">
                <div class="additional-content additional-content--top text-left">
                    <h3 :style="contentStyle" v-html="component.fields.additionalTopContent"></h3>
                </div>
            </div>
        </div>
        <div class="row">
            <div :class="columnClass" v-for="column in component.fields.columns">
                <div class="blowout-column-inner">
                    <h3 v-html="column.fields.columnTitle"></h3>
                    <template v-if="component.fields.displayAlternateLinks == false">
                        <a href="#" v-scroll-to="`#${column.fields.linkUrl}`" :style="linkColour(column.fields)">
                            <i class="fa fa-arrow-down mr-1"></i><span v-html="column.fields.linkText"></span>
                        </a>
                    </template>
                    <template v-else>
                        <a :href="column.fields.linkUrl" class="btn btn-primary text-center" target="_blank" :style="linkColour(column.fields)">
                            <span v-html="column.fields.linkText"></span>
                        </a>
                    </template>
                </div>
            </div>
        </div>
        <div class="row" v-if="component.fields.additionalBottomContent">
            <div class="col-12">
                <div class="additional-content additional-content--bottom text-right">
                    <h3 :style="contentStyle">
                        <i class="fa fa-arrow-down"></i>
                        <span v-html="component.fields.additionalBottomContent"></span>
                    </h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['entry', 'component', 'footer', 'header', 'currentslug', 'csrfName', 'csrfValue', 'componentKey'],
        computed: {
            columnClass() {
                const len = this.component.fields.columns.length;

                if (len === 1) {
                    return 'col-md-4 offset-md-4';
                }
                if (len === 2) {
                    return 'col-md-6';
                }
                if (len === 3) {
                    return 'col-md-4';
                }
                if (len === 4 || len === 8) {
                    return 'col-md-3 col-sm-6';
                }

                return 'col-md-4 col-sm-6';
            },
            contentStyle() {
                return 'color:'+this.component.fields.additionalContentColour;
            }
        },
        methods: {
            scrollToSection() {
                //@todo
            },
            linkColour(fields) {
                if (this.component.fields.displayAlternateLinks) {
                    return "color: #ffffff;";
                } else {
                    return "color: " + fields.linkTextColour + ";";
                }
            },
        }
    }
</script>
