<template>
    <div class="page-section services" :id="component.fields.sectionId">
        <div class="container">
            <div class="row mb-4">
                <div class="col-md-10 offset-md-1">
                    <h4 v-html="f.sectionTitle" :style="`color:${component.fields.sectionTitleColour};`"></h4>
                </div>
            </div>
            <div class="row">
                <a :href="service.fields.serviceLink" :class="'col-md-5'+(index > 0 ? '' : ' offset-md-1')+' service-rows-top mb-5'" v-for="(service, index) in firstTwo">
                    <img :src="service.fields.serviceImage[0]" :alt="service.fields.serviceDescription" class="img-fluid w-100 h-auto mb-2"/>
                    <p v-html="service.fields.serviceDescription" v-if="service.fields.serviceDescription" :style="`color:${service.fields.serviceDescriptionColour};`"></p>
                </a>
            </div>
            <div class="row" v-if="f.services.length > 2">
                <a :href="service.fields.serviceLink" class="col-md-4 service-rows-bottom mb-5" v-for="service in rest">
                    <img :src="service.fields.serviceImage[0]" :alt="service.fields.serviceDescription" class="img-fluid w-100 h-auto mb-2"/>
                    <p v-html="service.fields.serviceDescription" v-if="service.fields.serviceDescription" :style="`color:${service.fields.serviceDescriptionColour};`"></p>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Services',
        props: ['entry', 'component', 'footer', 'header', 'currentslug', 'csrfName', 'csrfValue', 'componentKey'],
        computed: {
            f() {
                return this.component.fields;
            },
            firstTwo() {
                console.log(this.component.fields.services);
                console.log(this.component.fields.services.slice(0, 2));
                if (this.component.fields.services.length <= 2) {
                    return this.component.fields.services.length;
                } else {
                    return this.component.fields.services.slice(0, 2);
                }
            },
            rest() {
                if (this.component.fields.services.length <= 2) {
                    return [];
                } else {
                    return this.component.fields.services.slice(2);
                }
            }
        }
    }
</script>